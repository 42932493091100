.login-content {
    width: 100%;
    padding: 15%;
    margin: auto;
    background-color: #fff;
}

.promotion-content {
    width: 100%;
    padding: 15%;
    margin: auto;
    height: 100vh;
}

.span-item {
    text-align: center;
    color: white !important;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
}

.a-item {
    color: white !important;
}

.small-item {
    text-align: center;
    color: white !important;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

.text-purple {
    color: #45377b !important;
}

.btn-purple {
    color: #ffffff;
    background-color: #45377b;
}

.h2-item {
    text-align: center;
    color: white !important;
}

.image-container {
    display: block !important;
    margin: 20px !important;
}

.pos-container {
    background-color: #4c4c4c;
}

.divider {
    width: 80%;
    height: 1px;
}

.divider-10 {
    width: 10% !important;
    height: 1px;
}

.divider-15 {
    width: 15% !important;
    height: 1px;
}

.divider-20 {
    height: 1px;
    width: 20% !important;
}

.divider-25 {
    height: 1px;
    width: 25% !important;
}

.divider-30 {
    height: 1px;
    width: 30% !important;
}

.divider-35 {
    height: 1px;
    width: 35% !important;
}

.divider-40 {
    width: 40% !important;
    height: 1px;
    border: 0.5px solid #45377b;
}

.divider-45 {
    width: 45% !important;
    height: 1px;
}

.divider-50 {
    width: 50% !important;
    height: 1px;
}

.divider-55 {
    width: 55% !important;
    height: 1px;
}

.divider-60 {
    width: 60% !important;
    height: 1px;
}

.divider-65 {
    width: 65% !important;
    height: 1px;
}

.divider-70 {
    width: 70% !important;
    height: 1px;
}

.divider-75 {
    width: 75% !important;
    height: 1px;
}

.divider-80 {
    width: 80% !important;
    height: 1px;
}

.divider-85 {
    width: 85% !important;
    height: 1px;
}

.divider-90 {
    width: 90% !important;
    height: 1px;
}

.divider-95 {
    width: 95% !important;
    height: 1px;
}

.divider-100 {
    width: 100% !important;
    height: 1px;
}

.pos-cielo {
    background-color: #0eacf0;
    height: 90px;
}

.pos-cielo img {
    padding: 20px;
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
}

.pos-safra {
    background-color: #151e45;
    height: 90px;
}

.pos-safra img {
    padding: 20px;
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
}

.pos-stone {
    background-color: whitesmoke;
    height: 90px;
}

.pos-stone img {
    padding: 20px;
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
}

.pos-getnet {
    background-color: #fd0302;
    height: 90px
}

.pos-getnet img {
    padding: 10px;
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
}

.pos-rede {
    background-color: #f2e123;
    height: 90px
}

.pos-rede img {
    padding: 10px;
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
}

.pos-pagseguro {
    background-color: #00bc99;
    height: 90px
}

.pos-pagseguro img {
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
}