a {
    text-decoration: none !important;
}

.brand {
    position: absolute;
    left: 50%;
    margin-left: -100px !important;
    /* 50% of your logo width */
    /* display: block; */
}

.dropdown-toggle,
a {
    color: white;
}

.dropdown-toggle,
a:hover {
    color: white;
}

.a:hover {
    color: white;
}

.badge-selfparking {
    color: #fff;
    background-color: #44377b;
}

.float-content {
    margin-top: 22px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #44377b;
    color: #FFF;
    border-radius: 10px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    padding: 20px;
}

@media only screen and (max-width: 2000px) {
    .float-content {
        width: 20%;
    }
}

@media only screen and (max-width: 800px) {
    .float-content {
        width: 90%;
    }
}

@media only screen and (max-width: 1000px) {
    .float-content {
        width: 30%;
    }
}


@media only screen and (max-width: 500px) {
    .float-content {
        width: 85%;
    }
}