.sucesso-licenca-content {
    width: 100%;
    padding: 15%;
    margin: auto;
    background-color: #44377b;
}

.span-item {
    text-align: center;
    color: white !important;
    font-size: 23px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
}

.a-item {
    color: white !important;
}

.small-item {
    text-align: center;
    color: white !important;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

.h2-item {
    text-align: center;
    color: white !important;
}

.image-container {
    display: flex;
    justify-content: center;
}
